.head-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
}
.head-image {
    width: auto;
    transition: max-width 1s, min-width 1s;
    border-radius: 40px;
}

.head-image-expanded {
    min-width: 500px;
    max-width: 520px;
}

.head-image-contracted {
    max-width: 210px;
    min-width: 200px;
}

.head-text {
    font-family: 'Hachi Maru Pop', cursive;
    margin: 5px 0 15px 0;
    transition: font-size 1s;
}

.head-text-expanded {
    font-size: 50px;
}

.head-text-contracted {
    font-size: 18px;
}
