.search-container {
    display: flex;
    justify-content: center;
    margin: 10px;
}
.search-input {
    padding: 10px 18px;
    font-size: 18px;
    width: 200px;
    height: 25px;
    border-width: 0;
    background: rgb(206, 182, 167);
    color: rgb(17, 9, 9);
    border-radius: 50px;
    box-shadow: 0 0 8px rgba(82, 61, 6, 0.5);
}

.search-input::placeholder {
    color: rgb(17, 9, 9);
}

.search-input:focus {
    outline: none;
}
