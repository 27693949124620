.result-name-card {
    color: rgb(63, 37, 37);
    background: rgb(199, 159, 127);
    margin: 10px 15px;
    min-width: 75px;
    padding: 10px 20px;
    border-radius: 15px;
    box-shadow: 0px 0px 5px rgba(31, 20, 20, 0.1);
    transition: background 0.35s;
    display: flex;
    justify-content: center;
}

.result-name-card:hover {
    background: rgba(248, 196, 154, 0.774);
    box-shadow: 0px 0px 5px rgba(31, 20, 20, 0.3);
}

.result-name {
    margin: 5px;
    font-weight: 500;
}

.card-link {
    text-decoration: none;
}
